<template>
  <div>
    <a-descriptions :bordered="bordered" :layout="layout" :title="title" :column="column">
      <a-descriptions-item v-for="(item, index) in list" :key="index" :label="item.label" :span="item.span">
        <template v-if="item.img">
          <div class="file-list" v-if="detail[item.key]">
            <img
              class="file-item"
              :src="f"
              v-for="(f, i) in detail[item.key].split(',')"
              :key="i"
              @click="previewImg(f, item)"
            />
          </div>
          <div v-else>无</div>
        </template>
        <template v-else-if="item.tag">
          <div v-if="detail[item.key]">
            <a-tag v-for="tag in getTagList(item.options, detail[item.key])" :key="tag.value">
              <open-data type="userName" :openid="tag.value" v-if="tag.is_user_id" />
              <span v-else>{{ tag.label }}</span>
            </a-tag>
          </div>
          <div v-else>-</div>
        </template>
        <template v-else-if="item.timestamp">
          <div v-if="detail[item.key]">{{ getTimeText(detail[item.key]) }}</div>
          <div v-else>-</div>
        </template>
        <template v-else-if="item.select">
          <div v-if="detail[item.key]">{{ getSelectText(item.options, detail[item.key]) }}</div>
          <div v-else>-</div>
        </template>
        <template v-else-if="item.textarea">
          <article v-if="detail[item.key]">
            <p class="detail-info-p" v-for="(p, i) in detail[item.key].split(/\r|\n/)" :key="i">{{ p }}</p>
          </article>
          <div v-else>-</div>
        </template>
        <template v-else-if="item.rich">
          <div class="rich-info" v-if="detail[item.key]" v-html="detail[item.key]"></div>
          <div v-else>-</div>
        </template>
        <template v-else-if="item.media">
          <div v-if="detail[item.key]" class="media-list">
            <div class="media-item" v-for="(m, i) in detail[item.key]" :key="i">
              <div class="media-preview">
                <video controls :src="m.url" :poster="m.poster" v-if="m.type == 'video'"></video>
                <img :src="m.url" @click="previewMedia(m, item)" v-else />
              </div>
            </div>
          </div>
          <div v-else>-</div>
        </template>
        <template v-else-if="item.file">
          <div v-if="detail[item.key]" class="files-list">
            <div class="files-item" :src="f" v-for="(f, i) in detail[item.key].split(',')" :key="i">
              <div class="files-item-name">{{ getUrlFileName(f) }}</div>
              <a-icon type="download" class="download-icon" @click="downloadFile(f)" />
            </div>
          </div>
          <div v-else>-</div>
        </template>
        <template v-else-if="item.slot">
          <vnodes :vnodes="item.slot(detail)" />
        </template>
        <div v-else>{{ detail[item.key] || "-" }}</div>
      </a-descriptions-item>
    </a-descriptions>
  </div>
</template>

<script>
import { timestampConvertString, getKeyTitle, getUrlFileName, downLoadFile } from "../../../../common/js/tool";

export default {
  name: "Descriptions",
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    detail: {
      type: Object,
      default: () => ({}),
    },
    layout: {
      type: String,
      default: "vertical",
    },
    bordered: {
      type: Boolean,
      default: true,
    },
    column: {
      type: Number,
      default: 3,
    },
    title: String,
  },
  data() {
    return {
      getUrlFileName,
    };
  },
  methods: {
    getTagList(options, value) {
      return value?.split(",").map((val) => {
        return options.find((item) => item.value == val);
      });
    },
    getTimeText(value) {
      return timestampConvertString(value);
    },
    getSelectText(options, value) {
      return getKeyTitle(options, value, "value", "label");
    },
    previewMedia(media, item) {
      let value = this.detail[item.key];
      let temp = value
        .filter((v) => v.type == "image")
        .map((img) => {
          return {
            url: img.url,
            title: item.label,
          };
        });
      this.current = media.url;
      this.urls = temp;
      this.show = true;
    },
    downloadFile(f) {
      downLoadFile(f, getUrlFileName(f));
    },
    previewImg(img, item) {
      let value = this.detail[item.key];
      if (typeof value === "string") {
        value = value.split(",").map((v) => {
          return {
            url: v,
            title: item.label,
          };
        });
      }
      this.$store.dispatch("showImgList", value, img);
    },
  },
};
</script>

<style lang="less" scoped>
.rich-info {
  max-height: 500px;
  overflow-y: auto;
}
</style>
