<template>
  <div>
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :layout="layout"
      :label-align="labelAlign"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-row :gutter="gutter">
        <a-col :span="24">
          <a-form-model-item label="申请审批名称" prop="name">
            <a-input
              v-model="form.name"
              placeholder="输入申请审批名称（最多30字）"
              :maxLength="30"
              allowClear
            ></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <a-form-model-item label="业务类型" prop="type">
            <a-select
              style="width: 100%"
              placeholder="请选择业务类型"
              :options="hrVolunteerTempTypes"
              v-model="form.type"
            >
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <a-form-model-item label="表单配置">
            <custom-form class="form-edit" ref="formEdit" hide-btn :list="form.detail" :dev="dev == 1" :edit.sync="edit"></custom-form>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>

<script>
import { hrVolunteerTempTypes } from "../../../../common/constant/template";
import { clone, dataConvertOptions } from "../../../../common/js/tool";
import customForm from "../../../../components/custom-form/custom-form";

export default {
  name: "ApplyForm",
  components: {
    customForm,
  },
  props: {
    initial: Object,
    halfLabelCol: Object,
    halfWrapperCol: Object,
    labelCol: Object,
    wrapperCol: Object,
    labelAlign: {
      type: String,
      default: "left",
    },
    layout: {
      type: String,
      default: "vertical",
    },
    gutter: {
      type: [Number, String, Array],
      default: 32,
    },
  },
  data() {
    return {
      form: {},
      rules: {
        name: [{ required: true, message: "请输入业务名称", trigger: "blur" }],
        type: [{ required: true, message: "请选择业务类型", trigger: "change" }],
      },
      edit: true,
      hrVolunteerTempTypes: dataConvertOptions(hrVolunteerTempTypes, "key", "title"),
    };
  },
  computed: {
    // 调试模式 用于固定表单字段修改
    dev() {
      return this.$route.query.d;
    },
  },
  watch: {
    initial: {
      handler() {
        this.init();
      },
      immediate: true,
    },
  },
  methods: {
    init() {
      let initial = this.initial;
      if (initial) {
        let form = clone(initial);
        // 表单配置
        if (form.detail) {
          form.detail = JSON.parse(form.detail);
        }
        this.form = form;
      }
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.ruleForm.validate((flag) => {
          if (flag) {
            this.$refs.formEdit
              .saveForm()
              .then((form_detail) => {
                let form = clone(this.form);
                form.detail = form_detail;
                resolve(form);
              })
              .catch((err) => {
                reject(err);
              });
          } else {
            reject();
          }
        });
      });
    },
    confirm(formData) {
      this.form = formData;
      this.visible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.form-edit {
  height: 500px;
}
</style>
