<template>
  <div class="content md no-padding flex-box vertical">
    <div class="area vertical">
      <ApplyForm ref="editForm" :initial="initial" v-show="edit" class="area-content"></ApplyForm>
      <div class="area-content" v-if="detail" v-show="!edit">
        <Descriptions
          :bordered="false"
          layout="horizontal"
          :list="basicList"
          :detail="detail"
          class="mb-24"
        ></Descriptions>
        <div class="descriptions-label">表单配置：</div>
        <custom-form class="form-edit" ref="formEdit" hide-btn :list="formControls" :edit.sync="formEdit"></custom-form>
      </div>
    </div>
    <a-space class="footer">
      <template v-if="edit">
        <a-button @click="handleCancel">取消</a-button>
        <a-button type="primary" @click="handleSave">保存</a-button>
      </template>
      <template v-else>
        <a-button @click="back">返回</a-button>
        <a-button type="primary" @click="handleEdit">编辑</a-button>
      </template>
    </a-space>
  </div>
</template>

<script>
import ApplyForm from "./components/ApplyForm.vue";
import Descriptions from "./components/Descriptions.vue";
import customForm from "../../../components/custom-form/custom-form";
import { getKeyTitle } from '../../../common/js/tool';
import { hrVolunteerTempTypes } from '../../../common/constant/template';

export default {
  name: "ActivityDetail",
  components: {
    ApplyForm,
    Descriptions,
    customForm,
  },
  data() {
    return {
      edit: false,
      detail: null,
      initial: null,
      basicList: [],
      editable: false,
      formEdit: false,
      formControls: [],
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      if (this.id) {
        this.$axios({
          url: `/admin/apply-temp/${this.id}`,
          method: "GET",
        }).then((res) => {
          this.detail = res;
          if (this.$route.query.edit) {
            this.handleEdit();
          } else {
            if (res.detail) {
              this.formControls = JSON.parse(res.detail);
            }
            this.getDescriptionsList();
          }
        });
      }
    },
    getDescriptionsList() {
      let basicList = [];
      basicList.push(
        {
          key: "name",
          label: "申请审批名称",
        },
        {
          label: "业务类型",
          slot: (detail) => {
            return <span>{getKeyTitle(hrVolunteerTempTypes, detail.type)}</span>
          }
        }
      );
      this.basicList = basicList;
    },
    handleEdit() {
      this.edit = true;
      this.initial = this.detail;
    },
    handleSave() {
      this.$refs.editForm.validate().then((form) => {
        this.$axios({
          url: `/admin/apply-temp/${this.id}`,
          method: "PATCH",
          data: form,
        }).then(() => {
          this.$message.success("保存成功");
          this.getDetail();
          this.handleCancel();
        });
      });
    },
    handleCancel() {
      if (this.$route.query.edit) {
        this.back();
      } else {
        this.edit = false;
        this.initial = null;
      }
    },
    back() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
}
.mb-24 {
  margin-bottom: 24px;
}
.descriptions-label {
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
}
</style>
